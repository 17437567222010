/*!
 * SpreadNShare 3.0 (http://www.virtualys.com)
 * Copyright 2008-2015 Virtualys / IFREMER
 */
.c_menu.l_core_singlelevel {
  line-height: 40px;
}
.c_menu.l_core_singlelevel a {
  text-decoration: none;
  color: #004f9e;
}
.c_menu.l_core_singlelevel a:focus,
.c_menu.l_core_singlelevel a:hover,
.c_menu.l_core_singlelevel a.active {
  color: #01bbf4;
}
.c_menu.l_core_singlelevel .horizontal .frame {
  height: 40px;
}
.c_menu.l_core_singlelevel .horizontal li {
  text-transform: uppercase;
}
.c_menu.l_core_singlelevel .horizontal li > a {
  padding: 0 11px;
}
.c_menu.l_core_singlelevel .horizontal .control {
  background: #f5f5f5;
  color: #fff;
}
.c_menu.l_core_singlelevel .horizontal .control:hover {
  background: #01bbf4;
  color: #004f9e;
}
.c_menu.l_core_singlelevel .horizontal .control.prev:hover span {
  border: none;
}
.c_menu.l_core_singlelevel .horizontal .control.next:hover span {
  border: none;
}
.c_menu.l_core_singlelevel .vertical ul {
  margin: 0;
  padding: 0 0 0 10px;
  list-style-type: none;
}
#header_topmenu {
  letter-spacing: .05em;
  font-weight: bold;
}
#header_topmenu .frame > ul {
  margin: 0 auto;
}
/*# sourceMappingURL=cpnt_snshtml5_menu.singlelevel-theme.css.map */